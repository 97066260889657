.App {
  text-align: center;
  max-width: 1200px;
  margin: auto;
  overflow-x: hidden;
  padding-right: 10px;
  padding-left: 10px;
}
.primary-color{
  color: #EDD3C5;
}
.App .bg-white {
  background-color: #1b4235;
}
nav ul li a{
  color: aliceblue !important;
}
.hero-section{
  background-image: url(../src/Assets/Lamahatta-Grove-Homestay.webp);
  width: 350px;
  height: 150px;
}
.c-bg1{
  background-image: url(../src/Assets/foods.webp);
  background-position: center;
}
.c-bg2{
  background-image: url(../src/Assets/bonfire.webp);
  background-position: center;
}
.c-bg3{
  background-image: url(../src/Assets/barbeque.webp);
  background-position: center;
}
.c-bg4{
  background-image: url(../src/Assets/wifi.webp);
  background-position: center;
}
.c-bg5{
  background-image: url(../src/Assets/cabs.webp);
  background-position: center;
}
.c-bg6{
  background-image: url(../src/Assets/guide.webp);
  background-position: center;
}
.c-avatar{
  text-align: -webkit-center;
}
.t-center{
  text-align: -webkit-center;
}
.f-ico img{
  width: 25px;
  height: 25px;
}
.breadcrumb1 a{
  color: white !important;
}
.custom-overlay__caption {
  background-color: rgba(0, 0, 0, .5);
  max-height: 240px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  padding: 5px;
  font-size: 90%;
}
.ReactGridGallery_tile:hover svg{
  display: none !important;
}